<template>
  <div>
    <b-card>
      <b-card-title>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col sm="2" v-if="info">
            <v-button v-b-tooltip.hover :title="info">
              <font-awesome-icon :icon="['far', 'question-circle']" />
            </v-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-body>
        <b-row>
          <b-col sm="12" class="text-center">
            <h4>
              <span v-if="!percent">
                {{ $filters.formatNumber(this.value) }}
              </span>
              <span v-else>
                {{ $filters.formatPercent(this.value) }}
              </span>
              <span v-if="description">{{ description }}</span>
            </h4>
          </b-col>
        </b-row>
        <b-row v-if="evolution">
          <b-col class="text-center">
            <h5 :class="this.evolution > 0 ? 'text-success' : 'text-danger'">
              {{ $filters.formatPercent(this.evolution) }}
              {{ this.$t("recap.previous_year") }}
            </h5>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    description: { type: String, default: null },
    percent: { type: Boolean, default: false },
    info: { type: String, default: null },
    evolution: { type: Number, default: null }
  }
};
</script>

<style></style>
